<template>
  <div>
    <v-card class="pa-8" v-if="!loading">
      <header-content :pageTitle="'List Gabungan'" />

      <div class="d-flex">
        <v-text-field
          v-model="searchQuery"
          outlined
          rounded
          dense
          color="primary"
          label="Cari kata kunci disini"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </div>

      <div class="d-flex flex-md-row flex-column mb-4">
        <v-select
          v-model="filters.province.value"
          :items="filters.province.items"
          item-text="label"
          item-value="id"
          label="Provinsi"
          dense
          outlined
        ></v-select>

        <div class="px-1" />

        <v-select
          v-model="filters.gender.value"
          :items="filters.gender.items"
          item-text="label"
          item-value="id"
          label="Jenis Kelamin"
          dense
          outlined
        ></v-select>

        <div class="px-1" />

        <v-select
          v-model="filters.age.value"
          :items="filters.age.items"
          item-text="label"
          item-value="id"
          label="Usia"
          dense
          outlined
        ></v-select>

        <div class="px-1" />

        <v-select
          v-model="filters.tenor.value"
          :items="filters.tenor.items"
          item-text="label"
          item-value="id"
          label="Tenor"
          dense
          outlined
        ></v-select>

        <div class="px-1" />

        <v-select
          v-model="filters.sector.value"
          :items="filters.sector.items"
          item-text="label"
          item-value="id"
          label="Sektor"
          dense
          outlined
        ></v-select>

        <div class="px-1" />

        <v-select
          v-model="filters.status.value"
          :items="filters.status.items"
          item-text="label"
          item-value="id"
          label="Status"
          dense
          outlined
        ></v-select>

        <div class="px-1" />

        <v-btn
          color="primary"
          rounded
          :disabled="isAllLoaded"
          class="ml-4 white--text text-none"
        >
          Apply Filter
        </v-btn>
      </div>

      <v-data-table
        class="cursor-pointer"
        :headers="headers"
        :items="payments"
        :items-per-page="10"
        :search="searchQuery"
        @click:row="toDetail"
      ></v-data-table>

      <v-btn
        color="primary"
        rounded
        :disabled="isAllLoaded"
        class="ml-4 white--text text-none"
        @click="loadMore"
      >
        Load More Data
      </v-btn>

      <v-btn
        color="primary"
        rounded
        :disabled="isAllLoaded"
        class="ml-4 white--text text-none"
        @click="loadAll"
      >
        Load All Data
      </v-btn>
    </v-card>
    <v-card class="pa-8" v-else>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
import store from "../../store/index.js";
import converters from "../../helpers/converters.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";

export default {
  components: { HeaderContent },

  data() {
    return {
      searchQuery: "",
      loading: true,
      isAllLoaded: false,
      filters: {
        province: {
          items: [],
          value: "",
        },
        gender: {
          items: [],
          value: "",
        },
        age: {
          items: [
            {
              label: "0-20 Tahun",
              id: 1,
            },
            {
              label: "21-40 Tahun",
              id: 2,
            },
            {
              label: ">40 Tahun",
              id: 3,
            },
          ],
          value: "",
        },
        tenor: {
          items: [
            {
              label: "0-24 Bulan",
              id: 1,
            },
            {
              label: "24-48 Bulan",
              id: 2,
            },
            {
              label: ">48 Bulan",
              id: 3,
            },
          ],
          value: "",
        },
        sector: {
          items: [],
          value: "",
        },
        status: {
          items: [
            {
              label: "Ongoing Loan",
              id: 1,
            },
            {
              label: "DPD 30",
              id: 2,
            },
            {
              label: "DPD 60",
              id: 3,
            },
            {
              label: "DPD 90+",
              id: 4,
            },
          ],
          value: "",
        },
      },
      headers: [
        {
          text: "ID Pembayaran",
          align: "start",
          sortable: true,
          value: "id_pembayaran",
        },
        { text: "Nilai Pembayaran", value: "nilai_pembayaran" },
        {
          text: "Sisa Pinjaman Berjalan",
          value: "sisa_pinjaman_berjalan_formatted",
        },
        {
          text: "Tanggal Pelunasan Penyelenggara",
          value: "tgl_pelunasan_penyelenggara_formatted",
        },
        {
          text: "Tanggal Pembayaran Penyelenggara",
          value: "tgl_pembayaran_penyelenggara_formatted",
        },
        { text: "Tanggal Jatuh Tempo", value: "tgl_jatuh_tempo_formatted" },
      ],
      payments: [],
      paymentsPagination: {
        firstPage: 1,
        lastPage: 100,
      },
    };
  },

  methods: {
    toDetail(item) {
      this.$router.push("/edit-payment/" + item.id);
    },
    async getPayment() {
      this.loading = true;

      await store
        .dispatch("office/fetchListPayment", this.paymentsPagination)
        .then((r) => {
          if (r) {
            this.loading = false;
          }
        });
      this.payments = store.state.office.listPayment;
      this.payments.forEach((a) => {
        a.tgl_pelunasan_penyelenggara_formatted = converters.date(
          a.tgl_pelunasan_penyelenggara
        );
        a.tgl_pembayaran_penyelenggara_formatted = converters.date(
          a.tgl_pembayaran_penyelenggara
        );
        a.tgl_jatuh_tempo_formatted = converters.date(a.tgl_jatuh_tempo);
        a.sisa_pinjaman_berjalan_formatted = converters.currency(
          a.sisa_pinjaman_berjalan
        );
      });
    },
    loadMore() {
      if (this.paymentsPagination.lastPage <= 30000) {
        this.paymentsPagination.lastPage += 1000;
        this.getPayment();
      } else {
        this.isAllLoaded = true;
      }
    },
    loadAll() {
      if (this.paymentsPagination.lastPage <= 30000) {
        this.paymentsPagination.lastPage += 30000;
        this.getPayment();
        this.isAllLoaded = true;
      }
    },
    async fetchFilterOptions() {
      await store.dispatch("options/fetchProvinces");
      this.filters.province.items = store.state.options.provinces;

      await store.dispatch("options/fetchGenders");
      this.filters.gender.items = store.state.options.genders;

      await store.dispatch("options/fetchWorkFields");
      this.filters.sector.items = store.state.options.workFields;
    },
  },

  mounted() {
    this.getPayment();
    this.fetchFilterOptions();
  },
};
</script>
